import React from 'react'
import { Link } from 'gatsby'

import {
  Breadcrumb,
  BreadcrumbItem,
  PageTitle,
  TitleBorder
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'

import data from '../json/navigation.json'


const HTMLSitemapPage = () =>  {
  return (
    <Layout>
      <SEO
        title="BSCS HTML sitemap"
        description="BSCS Science Learning is an independent nonprofit science education organization."
        canonicalUrl="https://bscs.org/html-sitemap/"
      />
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            HTML Sitemap
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>BSCS Science Learning</PageTitle>
        <TitleBorder />
        <div className="mt-5">
          <div className="w-full">
            <Link className='inline-anchor' to="/" title="Science Education Leadership Development Programs & Curriculum"><h2>Home</h2></Link>
            {data.navigation.map(section => {
              return (
                <React.Fragment key={`section-${section.title.toLowerCase().replace(/\s/g, '-')}`}>
                  <h2>{section.title}</h2>
                  <ul style={{listStyle: 'none'}}>
                    {section.items.map((item, index) => {
                      if (!item.onClick) {
                        return (
                          <li key={`item-${index}`}>
                            <Link
                              className='inline-anchor'
                              to={item.path}
                              title={item.itemTitle}
                            >
                              {item.itemTitle}
                            </Link>
                          </li>
                        )
                      }

                      return (<React.Fragment key={`item-${index}`} />)
                    })}
                  </ul>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HTMLSitemapPage

